<template>
  <div class="app-context">
    <div class="context complex">
      <div class="c-context">
        <directive :directiveInfo="directiveInfo"></directive>
        <div class="explain">
          <p>
            '부계정'은 닥터리퍼 서비스를 이용하시면서 1명의 직원에게 의사의
            닥터리퍼 아이디와와 동일한 권한을 부여하여
          </p>
          <p>
            간호사, 데스크에서 환자 방문확인 및 의뢰서 확인등의 업무처리를
            가능하게 하는 계정입니다.
          </p>
          <br />
          <p>
            <span> 사용자명</span> : 부계정을 사용하는 직원이름을 입력하실 수
            있습니다.
          </p>
          <p>
            <span> 휴대폰 번호</span> : 부계정을 사용하는 직원 번호, 휴대폰
            번호는 환자의 방문확인과 부계정의 비밀번호 변경에 사용됩니다.
          </p>
          <p>
            <span> 부계정 아이디</span> : 현재 창에서 부계정 사용여부를 체크하여
            부계정 생성과 동시에 [본계정!]으로 부계정의 아이디가 입력됩니다.
          </p>
          <p>ex) 본계정 : testID 일때 부계정 생성시, 부계정 : testID!</p>
          <p>
            <span> 비밀번호</span> : 현재 창에서 부계정 사용여부를 체크하여
            부계정 생성과 동시에 사용하실 비밀번호를 입력하실 수 있습니다.
          </p>
          <p>
            부계정으로 로그인시 부계정을 생성할때 입력한 사용자의 이름이
            표시됩니다.
          </p>
        </div>
        <v-container class="sub-container">
          <v-row class="sub-container-row">
            <v-col class="sub-container-row-title" md="4" cols="12">
              부계정 사용여부
            </v-col>
            <v-col md="8" cols="12">
              <span class="join document">
                <span class="j-article">
                  <div class="radio-input-wrap">
                    <div class="item">
                      <input
                        type="radio"
                        id="useSub"
                        v-model="form.use.value"
                        value="1"
                        @click="checkUser()"
                      />
                      <label for="useSub">사용</label>
                    </div>
                    <div class="item">
                      <input
                        type="radio"
                        id="restrictSub"
                        v-model="form.use.value"
                        value="0"
                        @click="checkUser()"
                      />
                      <label for="restrictSub">미사용</label>
                    </div>
                    <p class="warn-message" v-if="form.use.hasError">
                      {{ form.use.errorTxt }}
                    </p>
                  </div>
                </span>
              </span>
            </v-col>
          </v-row>
          <v-row
            class="sub-container-row"
            v-if="form.use.value === null || form.use.value === '1'"
          >
            <v-col class="sub-container-row-title" md="4" cols="12">
              사용자명
            </v-col>
            <v-col md="8" cols="12" class="input-wrap-col">
              <div class="input-wrap">
                <input
                  class="input-content"
                  type="text"
                  placeholder="성함을 입력해주세요."
                  v-model="form.name.value"
                  @keyup="checkName()"
                  @blur="checkName()"
                />
              </div>
              <p class="warn-message" v-if="form.name.hasError">
                <img src="@/assets/images/icons/error_24dp.svg" />
                <span>{{ form.name.errorTxt }}</span>
              </p>
            </v-col>
          </v-row>
          <v-row
            class="sub-container-row sub-id-row"
            v-if="form.use.value === null || form.use.value === '1'"
          >
            <v-col class="sub-container-row-title" md="4" cols="12">
              부계정 아이디
            </v-col>
            <v-col md="8" cols="12" class="sub-id-col">
              <span class="sub-id-col-span">
                {{ form.id.value }}
              </span>
              <span class="sub-id-col-span">
                (부계정 아이디는 '주계정 아이디!'로 자동생성됩니다.)
              </span>
            </v-col>
          </v-row>
          <v-row
            class="sub-container-row"
            v-if="
              form.use.value === null ||
              (form.use.value === '1' && this.GET_SUB_DATA.subYn === undefined)
            "
          >
            <v-col class="sub-container-row-title" md="4" cols="12">
              비밀번호 입력
            </v-col>
            <v-col md="8" cols="12" class="input-wrap-col">
              <div class="input-wrap">
                <input
                  type="password"
                  placeholder="비밀번호는 영문+숫자+특수문자의 조합으로 8~20자리로 설정해주세요."
                  v-model="form.password.value"
                  @keyup="checkPw()"
                  @blur="checkPw()"
                />
              </div>
              <p class="warn-message" v-if="form.password.hasError">
                <img src="@/assets/images/icons/error_24dp.svg" />
                <span>{{ form.password.errorTxt }}</span>
              </p>
            </v-col>
          </v-row>
          <v-row
            class="sub-container-row"
            v-if="
              form.use.value === null ||
              (form.use.value === '1' && this.GET_SUB_DATA.subYn === undefined)
            "
          >
            <v-col class="sub-container-row-title" md="4" cols="12">
              비밀번호 확인
            </v-col>
            <v-col md="8" cols="12" class="input-wrap-col">
              <div class="input-wrap">
                <input
                  type="password"
                  placeholder="비밀번호가 일치하지 않습니다. 다시 입력해주세요."
                  v-model="form.passwordConfirm.value"
                  @keyup="checkPwConfirm()"
                  @blur="checkPwConfirm()"
                />
              </div>
              <p class="warn-message" v-if="form.passwordConfirm.hasError">
                <img src="@/assets/images/icons/error_24dp.svg" />
                <span>{{ form.passwordConfirm.errorTxt }}</span>
              </p>
            </v-col>
          </v-row>
          <v-row
            class="sub-container-row"
            v-if="form.use.value === null || form.use.value === '1'"
          >
            <v-col class="sub-container-row-title" md="4" cols="12">
              휴대폰 번호
            </v-col>
            <v-col md="8" cols="12" class="input-wrap-col">
              <div class="input-wrap">
                <input
                  type="text"
                  placeholder="휴대폰 번호를 입력해주세요."
                  v-model="form.phone.value"
                  maxlength="13"
                  @keyup="checkPhone()"
                  @blur="checkPhone()"
                />
              </div>
              <p class="warn-message" v-if="form.phone.hasError">
                <img src="@/assets/images/icons/error_24dp.svg" />
                <span>{{ form.phone.errorTxt }}</span>
              </p>
            </v-col>
          </v-row>
        </v-container>

        <div class="join document" style="top: -50px">
          <div
            class="document-btn-wrap"
            v-if="
              form.use.value === '1' && this.GET_SUB_DATA.subYn === undefined
            "
          >
            <!-- <div class="item iconIn prev" @click="goPrev()">
              <img src="@/assets/images/menuIcon/prev_mini.svg" />
              <p>이전단계</p>
            </div> -->
            <div
              class="item iconIn"
              @click="next()"
              :class="{
                active: form.password.flag && form.passwordConfirm.flag,
              }"
            >
              <img src="@/assets/images/menuIcon/save.svg" />
              <p>정보저장</p>
            </div>
          </div>
          <div class="document-btn-wrap" v-else>
            <!-- <div class="item iconIn prev" @click="goPrev()">
              <img src="@/assets/images/menuIcon/prev_mini.svg" />
              <p>이전단계</p>
            </div> -->
            <div
              class="item iconIn"
              @click="next()"
              :class="{
                active: form.use.flag,
              }"
            >
              <img src="@/assets/images/icons/content-save-outline.svg" />
              <!-- <span class="material-icons-outlined google-icon">save</span> -->
              <p>정보저장</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import Directive from "@/components/join/Directive";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    Directive,
    DefaultPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      sideSet: {
        menuName: "mypage",
        sideTitle: "마이페이지",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      directiveInfo: {
        title: "부계정정보 입력",
        isTitleNewLine: false,
        content: "'부계정' 정보를 기입 또는 변경해주세요.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      form: {
        id: {
          value: null,
        },
        use: {
          value: null,
          flag: false,
          hasError: false,
          errorTxt: "사용여부를 선택해주세요.",
        },
        name: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "이름을 입력해주세요.",
        },
        password: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "비밀번호를 입력해주세요.",
        },
        passwordConfirm: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "비밀번호입력을 확인해주세요.",
        },
        phone: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "숫자만을 입력해주세요",
        },
      },
      popupSet: {},
    };
  },
  created() {
    // if (history.pushState !== undefined) {
    //   history.pushState({}, "", location.href);
    // }
    //console.log("########################################");
    //console.log(location.href);
    //console.log("########################################");
    this.getSubData();
  },
  mounted() {
    // document.querySelector(".sub-info").classList.add("active");
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("join", ["GET_SUB_DATA"]),
    genetateHyphen() {
      return (numData, isBirth) => {
        //console.log("가져온 번호들", numData);
        //console.log("팩스인가?", isBirth);
        const number = numData.toString().replace(/[^0-9]/g, "");
        let generatedNum = "";
        if (isBirth) {
          if (number.length < 5) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4, 2);
            generatedNum += "-";
            generatedNum += number.substr(6);
          }
          this.form.birth.value = generatedNum;
        } else {
          if (number.length < 4) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 3);
            generatedNum += "-";
            generatedNum += number.substr(6);
          } else {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 4);
            generatedNum += "-";
            generatedNum += number.substr(7);
          }
          this.form.phone.value = generatedNum;
        }
        return generatedNum;
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("join", ["SUB_SUBMIT", "FETCH_SUB"]),
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    initAuthInfo() {
      //console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    phoneFomatter(number) {
      //console.log("정보", number);
      //onsole.log("정보렝", number.length);
      let formatNum = "";
      if (number.length == 11) {
        formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      } else if (number.length == 8) {
        formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else {
        //console.log("핵검증", number.indexOf("02"));
        if (number.indexOf("02") == 0) {
          if (number.length === 9) {
            formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          } else {
            formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        } else {
          formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
      return formatNum;
    },
    checkUser() {
      this.form.use.flag = true;
      this.form.use.hasError = false;
    },
    async getSubData() {
      try {
        await this.FETCH_SUB().then(() => {
          //console.log("정보찾기", this.GET_SUB_DATA);
          this.form.id.value = localStorage.getItem("drId") + "!";
          if (this.GET_SUB_DATA.subYn !== undefined) {
            this.form.phone.value = this.phoneFomatter(this.GET_SUB_DATA.hpNo);
            this.form.name.value = this.GET_SUB_DATA.nm;
            this.form.phone.flag = true;
            this.form.name.flag = true;
            if (this.GET_SUB_DATA.subYn === "Y") {
              this.form.use.value = "1";
              this.form.use.flag = true;
            }
          } else {
            this.form.use.value = "0";
            this.form.use.flag = true;
          }
        });
      } catch (error) {
        console.log("error: ", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          console.log("error: ", error);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "부계정 정보를 가져오지 못했습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },

    goPrev() {
      window.history.go(-1);
    },
    checkName() {
      if (this.form.name.value !== null) {
        this.form.name.flag = false;
        this.form.name.hasError = true;
        const name = this.form.name.value.replace(/\s/gi, "");
        if (name === "") {
          this.form.name.flag = false;
          this.form.name.errorTxt = "이름을 입력해주세요.";
          return false;
        }
        const isHan = /^[ㄱ-ㅎ가-힣|a-z|A-Z]{2,21}$/;
        if (!isHan.test(name)) {
          this.form.name.errorTxt = "한글 및 영문만 입력할 수 있습니다.";
          this.form.name.flag = false;
          return false;
        }
        this.form.name.flag = true;
        this.form.name.hasError = false;
        this.form.name.errorTxt = "";
        return true;
      }
    },
    checkPw() {
      if (this.form.password.value !== null) {
        this.form.password.flag = false;
        this.form.password.hasError = true;
        const pw = this.form.password.value.replace(/\s/gi, "");
        if (pw === "") {
          this.form.password.flag = false;
          this.form.password.hasError = true;
          this.form.password.errorTxt = "비밀번호를 입력해주세요.";
          return false;
        }
        const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;
        if (!isPw.test(pw)) {
          this.form.password.errorTxt =
            "영문+숫자+특수문자의 조합으로 8~20자리로 설정해주세요.";
          this.form.password.flag = false;
          this.form.password.hasError = true;
          return false;
        }
        this.form.password.flag = true;
        this.form.password.hasError = false;
        this.form.password.errorTxt = "";
        return true;
      }
    },
    checkPwConfirm() {
      if (this.form.passwordConfirm.value !== null) {
        this.form.passwordConfirm.flag = false;
        this.form.passwordConfirm.hasError = true;
        const pwConfirm = this.form.passwordConfirm.value.replace(/\s/gi, "");
        if (pwConfirm === "") {
          this.form.passwordConfirm.flag = false;
          this.form.passwordConfirm.hasError = true;
          this.form.passwordConfirm.errorTxt = "비밀번호 확인을 입력해주세요.";
          return false;
        }
        if (pwConfirm !== this.form.passwordConfirm.value) {
          this.form.passwordConfirm.flag = false;
          this.form.passwordConfirm.hasError = true;
          this.form.passwordConfirm.errorTxt = "비밀번호가 일치하지 않습니다.";
          return false;
        }
        this.form.passwordConfirm.flag = true;
        this.form.passwordConfirm.hasError = false;
        this.form.passwordConfirm.errorTxt = "";
        return true;
      }
    },
    checkPhone() {
      if (this.form.phone.value !== null) {
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        const phone = this.form.phone.value.replace(/\s/gi, "");
        if (phone === "") {
          this.form.phone.flag = false;
          this.form.phone.hasError = true;
          this.form.phone.errorTxt = "핸드폰번호를 입력해주세요.";
          return false;
        }
        const isPhone = /^[0-9|-]{10,13}$/;
        if (!isPhone.test(phone)) {
          this.form.phone.errorTxt = "숫자만을 입력해주세요.";
          this.form.phone.flag = false;
          this.form.phone.hasError = true;
          return false;
        }
        this.form.phone.flag = true;
        this.form.phone.hasError = false;
        this.form.phone.errorTxt = "";
        return true;
      }
    },
    initFlag() {},
    checkFlag() {
      if (
        this.form.use.value === "1" &&
        this.GET_SUB_DATA.subYn === undefined
      ) {
        if (
          !this.form.name.flag ||
          !this.form.phone.flag ||
          !this.form.password.flag ||
          !this.form.passwordConfirm.flag
        ) {
          //console.log("체크 기본걸림");
          this.SET_POPUP(true);
          this.popupSet.title = "입력값 확인";
          this.popupSet.content =
            "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          if (!this.form.name.flag) {
            this.form.name.hasError = true;
          }
          if (!this.form.phone.flag) {
            this.form.phone.hasError = true;
          }
          if (!this.form.password.flag) {
            this.form.password.hasError = true;
          }
          if (!this.form.passwordConfirm.flag) {
            this.form.passwordConfirm.hasError = true;
          }
          return false;
        }
      } else {
        if (!this.form.use.flag) {
          this.SET_POPUP(true);
          this.popupSet.title = "입력값 확인";
          this.popupSet.content =
            "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          this.form.use.hasError = true;
          return false;
        }
      }
      return true;
    },
    async next() {
      if (this.checkFlag()) {
        const basicParam = {
          subYn: this.form.use.value === "1" ? "Y" : "N",
        };
        if (this.form.use.value === "1") {
          if (this.GET_SUB_DATA.subYn === undefined) {
            basicParam.passwd = this.form.password.value;
          }
          if (this.form.name.value !== "") {
            basicParam.nm = this.form.name.value;
          }
          if (this.form.phone.value !== "") {
            basicParam.hpNo = this.form.phone.value.replace(/-/gi, "");
          }
        }

        try {
          await this.SUB_SUBMIT(basicParam).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "부계정정보 변경완료";
            this.popupSet.content = "부계정정보가 정상적으로 변경되었습니다.";
            this.popupSet.popType = "custom";
            this.popupSet.cancelBtnText = undefined;
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.nextLink = "/v/mypage/sub";
            this.popupSet.destination = true;
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
  },
  destroyed() {
    this.SET_POPUP(false);
  },
};
</script>

<style lang="scss" scoped>
.j-article.last {
  border: 0px solid #707070 !important;
  border-width: 1px 0 1px 0 !important;
}
@import "@/assets/css/join";
@import "@/assets/css/common";
@import "@/assets/css/sideContext";
@import "@/assets/scss/sub";
</style>
